import { MapPin, Mail } from 'lucide-react'

export default function Component() {
  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow bg-cover bg-center bg-no-repeat" 
            style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/animation_electric.gif)` }}>
      </main>
      
      <footer className="w-full bg-purple-950 bg-opacity-90 text-pink-300 p-4">
        <div className="container mx-auto flex justify-end">
          <div className="flex flex-col items-start">
            <div className="flex items-start mb-2">
              <MapPin className="mr-2 h-5 w-5 mt-1 text-pink-500" />
              <address className="not-italic text-sm">
                2nd Floor College House,<br />
                17 King Edwards Road,<br />
                Ruislip, London,<br />
                United Kingdom, HA4 7AE
              </address>
            </div>
            <div className="flex items-center">
              <Mail className="mr-2 h-5 w-5 text-pink-500" />
              <a href="mailto:sales@skewed.digital" className="text-sm hover:text-pink-400 transition-colors">
                sales@skewed.digital
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}